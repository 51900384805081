@font-face {
  font-family: "Menlo";
  src: url("./data/Menlo-Regular.ttf");
}
@import url("https://use.typekit.net/ekb8vxk.css");

body {
  margin: 0;
  padding: 0;
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  padding-bottom: 1em;
}
.vstack {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.hidden {
  display: none !important;
}
.terminal-wrapper {
  padding-top: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5em;
  overflow: hidden;
  min-height: 100vh;
  height: fit-content;
  padding-left: 1em;
}
.cl-text,
.cl-input {
  font-size: 1.2em;
  margin: 0;
  color: white;
  font-family: "Menlo";
  width: fit-content;
  position: relative;
  background-color: black;
  border: none;
}
.cl-input {
  color: #008f11;
  width: fit-content;
  width: 5em;
  border: 1px solid white;
}
.cl-text::before {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  animation: slowtype_cursor 0.4s linear;
}
.cl-text::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 2.5px;
  height: 100%;
  width: 10px;
}
.cl-button {
  background-color: black;
  color: white;
  border: 1px solid white;
  padding: 0.5em 1em;
  font-size: 1em;
  font-family: "Menlo";
  font-weight: bold;
  z-index: 9999 !important;
}
.cl-button:hover,
.cl-button:focus {
  background-color: white;
  color: black;
}
.active-cursor {
  animation: 2s linear 1s infinite both running cursor_blink;
}
.terminal-wrapper {
  background-color: black;
}
@keyframes slowtype_cursor {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
@keyframes cursor_blink {
  0% {
    background-color: black;
  }
  50% {
    background-color: #008f11;
  }
  100% {
    background-color: black;
  }
}
section {
  background: hsla(265, 53%, 29%, 1);
  background: linear-gradient(
    90deg,
    hsla(265, 53%, 29%, 1) 0%,
    hsla(24, 93%, 73%, 1) 100%
  );
  font-family: source-serif-pro, serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  min-height: 100vh;
  height: fit-content;
}
section h1 {
  font-weight: 900;
  font-size: 4.25em;
  margin: 0;
}
section h2 {
  margin: 0;
  text-align: center;
  font-size: 1.5em;
  padding: 0em 0em;
}
section h3 {
  margin: 0;
  font-size: 1.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0em 0.125em;
  font-weight: 500;
  cursor: pointer;
}
section button {
  border: none;
}
section header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.25em 0.25em;
  margin: 3em 0;
  flex-wrap: wrap;
  margin-top: 1.5em;
  max-width: fit-content !important;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
}
.col {
  display: flex;
  flex-direction: column;
  gap: 1em 0;
  width: 30vw;
  min-width: min(97%, 400px);
  max-height: fit-content !important;
}

@media screen and (max-width: 1000px) {
  .card-wrapper {
    width: 97%;
    justify-content: center;
  }
}
.card,
.card-editor {
  border-radius: 2em;
  background-color: white;
  transition: 0.3s;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding: 0.5em;
  padding-bottom: 2em;
  flex-wrap: wrap;
  overflow: scroll none;
  width: 30em;
  min-width: 90%;
  width: 25vw;
}
.card h1,
.card-editor .h1 {
  padding: 0.25em 0.5em;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 3em;
  font-family: source-serif-pro, serif;
  font-weight: 800;
  text-align: center;
}
.card h2,
.card-editor .h2 {
  font-weight: 400;
  font-size: 1.5em;
  font-family: source-serif-pro, serif;
}
textarea.h1,
input.h1,
textarea.h2,
textarea.h3 {
  border: 3px solid #f87171;
  border-radius: 0.25em;
}
textarea.h3 {
  height: 30vh;
  width: 80%;
}
textarea {
  resize: none;
  width: 70%;
  text-align: center;
}
.card:hover {
  scale: 1.01;
}
.card ul .card-editor ul {
  margin-right: auto;
  font-size: 1.25em;
  margin-top: 0.25em;
}
li {
  font-size: 1.25em;
}
.mc-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.mc-text {
  font-size: 1em;
  font-weight: 100;
  color: white;
  font-family: "Menlo";
}
.choice-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1em;
}
.choice-wrapper input[type="radio"] {
  height: 1.25em;
  width: 1.25em;
  border-radius: 0;
  background-color: red;
}
.question-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.5em;
}
.wi-input {
  font-size: 1em;
  padding: 0.25em;
  width: min(25em, 100%) !important;
  margin: 0;
  color: white;
  font-family: "Menlo";
  width: fit-content;
  position: relative;
  background-color: black;
  border: 2px solid white;
}
.hide {
  display: none;
}
.new-post-button:hover {
  transform: scale(1.1);
}
.noscroll-all {
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .noscroll-all {
    overflow: visible;
  }
}
.greyed-out:after {
  content: "";
  height: 1000vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.4);
}
.card-editor button:hover {
  transform: scale(0.9);
}

.new-post-button {
  background-color: #e2e8f0;
  border-radius: 100%;
}
.view-selector .option {
  font-size: 1.25em;
  cursor: pointer;
  text-align: center;
}
.view-selector {
  margin-top: 1em;
  border-top: none;
  max-width: 90%;
}
.option.active {
  background-color: white;
  color: black;
  padding: 0.125em 0.75em;
  border-radius: 1em;
}
.post {
  font-size: 0.9em;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  width: 90%;
  max-width: 45em;
  position: relative;
}
.post img {
  height: 20em;
  object-fit: cover;
  min-width: 100%;
  width: 100%;
}
.post h1 {
  font-size: 2.25em;
  margin-left: 0.25em;
}
.post h2 {
  width: 100%;
  text-align: left;
  margin-left: 0.75em;
  font-weight: 100;
  font-size: 1.5em;
}
.post h3 {
  font-size: 1.33em;
}
.post-page-wrapper {
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.post-page-wrapper h1,
.post-page-wrapper h2,
.post-page-wrapper p {
  width: min(90%, 70em) !important;
  max-width: 100% !important;
  overflow-x: hidden !important;
  padding: 0 !important;
  margin: 0;
  font-family: "Source Serif Pro", serif !important;
}
.post-page-wrapper h1 {
  text-align: center;
  margin-top: 0.75em;
  padding: 0em 1em;
}
.post-page-wrapper h2 {
  font-weight: 100;
  text-align: center;
  font-size: 1.25em;
  margin-bottom: 0.75em;
}
.post-page-wrapper img {
  object-fit: cover;
  max-width: min(90%, 50em);
  border-radius: 2em;
  border: 3px solid white !important;
  margin-bottom: 1em;
}
.post-page-wrapper p {
  font-size: 1.25em;
  margin: 0 auto;
  text-indent: 1em;
  line-height: 1.5em;
  max-width: 50em !important;
}
.post-page-wrapper .content-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25em;
  margin-left: auto;
}
.post-page-wrapper li {
  font-size: 1.25em;
}
.post-page-wrapper ul {
  margin: 0 auto;
}
a {
  text-decoration: inherit;
  color: inherit;
  max-width: fit-content;
}
a:hover {
  text-decoration: underline;
}
.post a,
.post img {
  max-height: 25em;
  min-width: 100%;
}
.create-website-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}
.create-website-wrapper input,
.contact-form-wrapper input,
.contact-form-wrapper textarea {
  font-size: 1.6em;
  font-family: "Source Serif Pro";
  padding-right: 3em;
  border: none;
}
.create-website-wrapper button,
.contact-form-wrapper button {
  font-family: "Source Serif Pro";
  font-size: 1.85em;
  width: fit-content;
  padding: none;
  font-weight: 700;
  border: none;
  color: white;
  padding: 0em 1em;
  margin: 0 auto;
  background-color: #c084fc;
  border-radius: 1em;
}
.create-website-wrapper label {
  font-size: 1.25em;
}
@keyframes rotate-forever {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input[type="file"]::file-selector-button {
  font-family: source-serif-pro, serif !important;
  border: none;
  padding: 0em 1em;
  margin-right: 0.5em;
  margin-left: none;
}
.question {
  font-size: 1.6em;
  font-style: italic;
}
.ans {
  font-size: 1.4em;
  margin-bottom: 0.75em;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  margin-top: 1.5em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1em;
  background-color: white;
  box-shadow: 0em 0em 2em 0 black;
  max-width: max-content;
  z-index: 99999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.33em;
  padding-bottom: 1.2em !important;
  width: 30em;
  max-width: 90% !important;
  color: black;
  align-items: center;
}

.alert-wrapper h1 {
  padding: 0.25em 1em;
  padding-bottom: 0;
  color: vblack !important;
}

.alert-wrapper p {
  text-align: center;
  font-size: 1.25em;
  max-width: 90%;
  margin: 0 auto;
  color: black !important;
}

.alert-wrapper .options-wrapper {
  display: flex;
  justify-content: center;
  gap: 1em;
  width: 90%;
}

.alert-wrapper .options-wrapper button {
  padding: 0.25em 1em;
  max-width: 12em;
  font-family: "Source Serif Pro", serif !important;
  font-size: 1.3em;
  border: none;
  width: 100% !important;
  border-radius: 0.25em;
  margin: 0 auto;
  margin-top: 0.5em;
}
.basic-button-1 {
  font-family: "Source Serif Pro";
  font-size: 1.1em;
  margin-left: 0.5em;
  padding: 0.25em 0.75em;
}
.basic-button-1:hover {
  background-color: #cbd5e1;
}
